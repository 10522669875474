<template>
    <TabContentView
        v-model="currentSlug"
        :tabs="tabs"
        :slug="currentSlug"
        :url-param="param"
    >
        <template #tab-heading>
            <h3 v-if="data.tabHeading" class="mb-4">
                {{ data.tabHeading }}
            </h3>
        </template>
        <template #content>
            <div
                v-for="section in data.sections"
                :key="'section-' + section.slug"
            >
                <LeadTimesSection
                    v-if="section.slug === currentSlug"
                    :section="section"
                />
            </div>
        </template>
        <template #cta>
            <CtaCard
                v-if="data.cta"
                class="mt-4"
                :link-url="data.cta.link?.url"
                :link-label="data.cta.link?.label"
                :text="data.cta.text"
                :heading="data.cta.heading"
            />
        </template>
    </TabContentView>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import LeadTimeType from '~/types/LeadTimeType';
import LeadTimesSection from '~/components/page-building/lead-times/LeadTimesSection.vue';
import TabContentView from '~/components/page-building/components/tab-content-view/TabContentView.vue';
import TabContentViewTabType from '~/types/TabContentViewTabType';
import LeadTimeSectionType from '~/types/LeadTimeSectionType';
import LeadTimeViewType from '~/types/LeadTimeViewType';
import CtaCard from '~/components/page-building/components/CtaCard.vue';

const props = defineProps<{
    data: LeadTimeViewType;
}>();

const route = useRoute();
const tabs = ref<TabContentViewTabType[]>([]);
const param = ref<string>('leadTimeType');

const urlParam = computed<string | null>(() => {
    return Object.keys(route.query).length > 0 &&
        typeof route.query[param.value] === 'string' &&
        route.query[param.value] !== ''
        ? route.query[param.value]
        : null;
});

const preSelectedTab = computed<LeadTimeSectionType | undefined>(() => {
    return props.data.sections.find(
        (item: LeadTimeSectionType) => item.slug === urlParam.value,
    );
});

const currentSlug = ref<string | null>(
    preSelectedTab.value
        ? preSelectedTab.value.slug
        : props.data.sections[0].slug,
);

const { data: leadTimesData } = await useFetch('/nuxt-api/lead-times');
Object.values(props.data.sections).forEach(
    (productType: LeadTimeSectionType) => {
        tabs.value.push({
            image: productType.image,
            label: productType.title,
            slug: productType.slug,
        });
        if (leadTimesData.value) {
            Object.values(leadTimesData.value).forEach((data: any) => {
                if (data.type === productType.type) {
                    productType.leadTimes.push({
                        slug: data.slug,
                        title: data.title,
                        link: data.link,
                        notes: data.notes,
                        sizes: data.sizes,
                    } as LeadTimeType);
                }
            });
        }
    },
);
</script>
