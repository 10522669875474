<template>
    <BlockContainer
        v-editable="blok"
        :background-color="blok.background_color"
        :bottom-angle="blok.bottom_angle"
        :angle-background-color="nextBackgroundColor"
        :arrow-callout="leadTimeViewData.arrowCallout"
        divider-arrow-class="right-[15%] bottom-6 md:-bottom-2 xl:bottom-10"
    >
        <LeadTimesView :data="leadTimeViewData" class="py-12 md:py-28" />
    </BlockContainer>
</template>

<script setup lang="ts">
import BlockContainer from '~/components/BlockContainer.vue';
import LeadTimesView from '~/components/page-building/lead-times/LeadTimesView.vue';
import LeadTimeSectionType from '~/types/LeadTimeSectionType';
import LeadTimeViewType from '~/types/LeadTimeViewType';
import ImageType from '~/types/ImageType';
import { slugify } from '~/utils/helpers';
import LinkType from '~/types/LinkType';
import CtaCardType from '~/types/CtaCardType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const leadTimeViewData = computed<LeadTimeViewType>(() => {
    const sections = props.blok.sections
        ? props.blok.sections.map((section: any) => {
              return {
                  image: {
                      title: section.image.title,
                      alt: section.image.alt,
                      src: section.image.filename,
                  } as ImageType,
                  title: section.title,
                  type: section.type,
                  slug: slugify(section.title),
                  leadTimes: [],
                  vEditable: section,
              } as LeadTimeSectionType;
          })
        : [];
    return {
        tabHeading: props.blok.tab_heading,
        sections,
        cta:
            props.blok.cta && props.blok.cta[0]
                ? ({
                      heading: props.blok.cta[0].heading,
                      text: richTextToHtml(props.blok.cta[0].text) as string,
                      link: {
                          label: props.blok.cta[0].link_label,
                          url: props.blok.cta[0].link.cached_url,
                          vEditable: props.blok.cta[0].link,
                      } as LinkType,
                  } as CtaCardType)
                : null,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        vEditable: props.blok,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
    } as LeadTimeViewType;
});
const nextBackgroundColor = computed<string | null>(() => {
    return props.nextBlock ? props.nextBlock.background_color : null;
});
</script>
